import {Image, ImageTestId} from 'components/Image';
import {reverseUrl} from 'lib/router';
import {TestIdProp} from 'lib/testing/types';
import {useRouter} from 'next/router';
import React, {useCallback} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Button, ButtonTestId} from 'uikit/Button';
import styles from './index.module.scss';

const messages = defineMessages({
  button: {
    defaultMessage: 'Learn more',
    description: 'Page not found: Jump button text',
  },
  code: {
    defaultMessage: 'Error 404',
    description: 'Page not found: Text with error code',
  },
  message: {
    defaultMessage: 'But we will find and deliver products from trustworthy Chinese manufacturers to your warehouse',
    description: 'Page not found: Text',
  },
  title: {
    defaultMessage: 'Page not found',
    description: 'Page not found: Title',
  },
});

export type NotFoundTestId = {
  button: ButtonTestId;
  image: ImageTestId;
};

type Props = TestIdProp<NotFoundTestId>;

export function NotFound({testId}: Props): React.ReactElement {
  const router = useRouter();
  const handleClick = useCallback(() => {
    router.push(reverseUrl.main());
  }, [router]);

  return (
    <div className={styles.container} data-test-id={testId}>
      <div className={styles.content}>
        <div className={styles.code}>
          <FormattedMessage {...messages.code} />
        </div>
        <div className={styles.title}>
          <FormattedMessage {...messages.title} />
        </div>
        <div className={styles.message}>
          <FormattedMessage {...messages.message} />
        </div>
        <div className={styles.actions}>
          <Button color='black' onClick={handleClick} size='x-large' testId={testId?.button}>
            <FormattedMessage {...messages.button} />
          </Button>
        </div>
      </div>
      <Image className={styles.image} src='/illustrations/notFound.svg' testId={testId?.image} />
    </div>
  );
}

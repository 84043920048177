import {Layout} from 'components/Layout';
import {Reducer} from 'components/Layout/Reducer';
import {PageErrorContainer} from 'components/PageErrorContainer';
import {NextPageContext} from 'next';
import {ErrorProps} from 'next/error';
import React from 'react';
import {rootTestId} from 'testId';

export default function Error({statusCode}: ErrorProps): React.ReactElement {
  return (
    <Layout>
      <Reducer>
        <PageErrorContainer statusCode={statusCode} testId={rootTestId?.errorPage} />
      </Reducer>
    </Layout>
  );
}

Error.getInitialProps = ({res, err}: NextPageContext): ErrorProps => {
  const statusCode = res?.statusCode || err?.statusCode || 0;

  return {statusCode};
};

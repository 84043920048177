import {NotFound, NotFoundTestId} from 'components/NotFound';
import {Oops, OopsTestId} from 'components/Oops';
import {TestIdProp} from 'lib/testing/types';
import {isTransportResponse} from 'lib/transport/types';
import React from 'react';

export type PageErrorTestId = {
  notFound: NotFoundTestId;
  oops: OopsTestId;
};

type Props = TestIdProp<PageErrorTestId> & {
  error?: unknown;
  statusCode?: number;
};

export function PageError({error, statusCode, testId}: Props): React.ReactElement {
  if ((isTransportResponse(error) && error.status === 404) || statusCode === 404) {
    return <NotFound testId={testId?.notFound} />;
  }

  return <Oops testId={testId?.oops} />;
}

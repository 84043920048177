import {Link, LinkTestId} from 'components/Link';
import {Logo as LogoIcon} from 'components/Logo';
import {PageError, PageErrorTestId} from 'components/PageError';
import {reverseUrl} from 'lib/router';
import {TestIdProp} from 'lib/testing/types';
import React from 'react';
import styles from './index.module.scss';

export type PageErrorContainerTestId = {
  logo: LinkTestId;
  pageError: PageErrorTestId;
};

type Props = TestIdProp<PageErrorContainerTestId> & {
  statusCode?: number;
};

export function PageErrorContainer({statusCode, testId}: Props): React.ReactElement {
  return (
    <div className={styles.container} data-test-id={testId}>
      <div className={styles.logo}>
        <Link className={styles.link} href={reverseUrl.main()} testId={testId}>
          <LogoIcon />
        </Link>
      </div>
      <div className={styles.content}>
        <PageError statusCode={statusCode} testId={testId?.pageError} />
      </div>
    </div>
  );
}
